import { create } from 'apisauce';
import queryStringParser from 'qs';

const SESSION_EXPIRED_ERR_MSG = 'Your session expired. Please sign in again to continue.';
export const api = create({
  baseURL: '/',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

api.addRequestTransform(request => {
  request.paramsSerializer = params =>
    queryStringParser.stringify(params, { arrayFormat: 'brackets' });
  request.headers['X-CSRF-Token'] = document.head.querySelector('[name=csrf-token]').content;
});

api.addMonitor(response => {
  if (response.status === 401 && response.data.error === SESSION_EXPIRED_ERR_MSG) {
    window.location.reload();
  }
});

const initCommentsAPI = (url, commentableRole = 'general') => ({
  index: () => api.get(url, { commentable_role: commentableRole }),
  create: (comment, attachments = []) =>
    api.post(url, {
      comment: { body: comment, attachments, commentable_role: commentableRole },
    }),
});

export const API = {
  selectors: {
    customEmailTemplates: {
      modelAttributes: {
        index: params => api.get('/api/custom_email_templates/model_attributes', params),
        show: id => api.get(`/api/custom_email_templates/model_attributes/${id}`),
      },
      models: {
        index: params => api.get('/api/custom_email_templates/models', params),
        show: id => api.get(`/api/custom_email_templates/models/${id}`),
      },
      index: params => api.get('/api/custom_email_templates', params),
      show: id => api.get(`/api/custom_email_templates/${id}`),
    },
    buildingsFloors: {
      index: params => api.get('/api/buildings_floors', params),
      show: id => api.get(`/api/buildings_floors/${id}`),
    },
    document_types: {
      index: params => api.get('/api/document_types', params),
      show: id => api.get(`/api/document_types/${id}`),
    },
    peopleGroups: {
      index: params => api.get('/api/people_groups', { available_people_groups: true, ...params }),
      indexOfManageable: params =>
        api.get('/api/people_groups', {
          available_people_groups_for_manager: true,
          ...params,
        }),
      show: id => api.get(`/api/people_groups/${id}`),
    },
    people: {
      index: params => api.get('/api/people', params),
      show: id => api.get(`/api/people/${id}`),
    },
    personTypes: {
      index: params => api.get('/api/person_types', params),
      show: id => api.get(`/api/person_types/${id}`),
    },
    labs: {
      index: params => api.get('/api/labs', params),
      show: id => api.get(`/api/labs/${id}`),
    },
    swaMeasureTypes: {
      index: params => api.get('/api/safe_work_authorization_measure_types', params),
      show: id => api.get(`/api/safe_work_authorization_measure_types/${id}`),
    },
    equipments: {
      index: params => api.get('/api/equipment', params),
    },
    generalEquipments: {
      index: params => api.get('/api/equipment', params),
      show: id => api.get(`/api/equipment/${id}`),
    },
    portableFireExtinguishers: {
      index: params => api.get('/api/pf_es', params),
      show: id => api.get(`/api/pf_es/${id}`),
    },
    incidents: {
      index: params => api.get('/api/incidents', params),
      show: id => api.get(`/api/incidents/${id}`),
    },
    accidents: {
      index: params => api.get('/api/accidents', params),
      show: id => api.get(`/api/accidents/${id}`),
    },
    incidentRequests: {
      index: params => api.get('/api/incident_requests', params),
      show: id => api.get(`/api/incident_requests/${id}`),
    },
    issueTypes: {
      index: params => api.get('/api/issue_types', params),
      show: id => api.get(`/api/issue_types/${id}`),
    },
    bsls: {
      index: params => api.get('/api/bsls', params),
      show: id => api.get(`/api/bsls/${id}`),
    },
    buildings: {
      index: params => api.get('/api/buildings', params),
      show: id => api.get(`/api/buildings/${id}`),
    },
    campuses: {
      index: params => api.get('/api/campuses', params),
      show: id => api.get(`/api/campuses/${id}`),
    },
    construction_projects: {
      index: params => api.get('/api/construction_projects', params),
      show: id => api.get(`/api/construction_projects/${id}`),
    },
    departments: {
      index: params => api.get('/api/departments', params),
      show: id => api.get(`/api/departments/${id}`),
    },
    divisions: {
      index: params => api.get('/api/divisions', params),
      show: id => api.get(`/api/divisions/${id}`),
    },
    floors: {
      index: params => api.get('/api/floors', params),
      show: id => api.get(`/api/floors/${id}`),
    },
    labUseTypes: {
      index: params => api.get('/api/labs_use_types', params),
      show: id => api.get(`/api/labs_use_types/${id}`),
    },
    permitTypes: {
      index: params => api.get('/api/permit_types', params),
      show: id => api.get(`/api/permit_types/${id}`),
    },
    permitStatuses: {
      index: params => api.get('/api/permit_statuses', params),
      show: id => api.get(`/api/permit_statuses/${id}`),
    },
    permitsIssuers: {
      index: params => api.get('/api/permits_issuers', params),
      show: id => api.get(`/api/permits_issuers/${id}`),
    },
    eventTypes: {
      index: params => api.get('/api/event_types', params),
      show: id => api.get(`/api/event_types/${id}`),
    },
    generalRequestTypes: {
      index: params => api.get('/api/general_request_types', params),
      show: id => api.get(`/api/general_request_types/${id}`),
    },
    submissionChannels: {
      index: params => api.get('/api/submission_channels', params),
      show: id => api.get(`/api/submission_channels/${id}`),
    },
    emergencyContactProfiles: {
      index: params => api.get('/api/emergency_contact_profiles', params),
      show: id => api.get(`/api/emergency_contact_profiles/${id}`),
    },
    hazardRatings: {
      index: params => api.get('/api/hazard_ratings', params),
      show: id => api.get(`/api/hazard_ratings/${id}`),
    },
    logoProfiles: {
      index: params => api.get('/api/logo_profiles', params),
      show: id => api.get(`/api/logo_profiles/${id}`),
    },
    ppes: {
      index: params => api.get('/api/ppes', params),
      show: id => api.get(`/api/ppes/${id}`),
    },
    accessRestrictions: {
      index: params => api.get('/api/access_restrictions', params),
      show: id => api.get(`/api/access_restrictions/${id}`),
    },
    wasteCollectionTypes: {
      index: params => api.get('/api/waste_collection_types', params),
      show: id => api.get(`/api/waste_collection_types/${id}`),
    },
    wasteChemicalNames: {
      index: params => api.get('/api/waste_chemical_names', params),
      show: id => api.get(`/api/waste_chemical_names/${id}`),
    },
    wasteChemicalItems: {
      index: params => api.get('/api/waste_chemical_items', params),
      show: id => api.get(`/api/waste_chemical_items/${id}`),
    },
    wasteGenerators: {
      index: params => api.get('/api/waste_generators', params),
      show: id => api.get(`/api/waste_generators/${id}`),
    },
    containerTypes: {
      index: params => api.get('/api/container_types', params),
      show: id => api.get(`/api/container_types/${id}`),
    },
    ums: {
      index: params => api.get('/api/ums', params),
      show: id => api.get(`/api/ums/${id}`),
    },
    labels: {
      index: params => api.get('/api/labels', params),
      show: id => api.get(`/api/labels/${id}`),
    },
    ibcStatuses: {
      index: params => api.get('/api/ibc_statuses', params),
      show: id => api.get(`/api/ibc_statuses/${id}`),
    },
    biologicalAgentTypes: {
      index: params => api.get('/api/biological_agent_types', params),
      show: id => api.get(`/api/biological_agent_types/${id}`),
    },
    biologicalBugSpecies: {
      index: params => api.get('/api/biological_bug_species', params),
      show: id => api.get(`/api/biological_bug_species/${id}`),
    },
    biologicalStrains: {
      index: params => api.get('/api/biological_strains', params),
      show: id => api.get(`/api/biological_strains/${id}`),
    },
    absls: {
      index: params => api.get('/api/absls', params),
      show: id => api.get(`/api/absls/${id}`),
    },
    replicationAbilities: {
      index: params => api.get('/api/replication_abilities', params),
      show: id => api.get(`/api/replication_abilities/${id}`),
    },
    riskGroups: {
      index: params => api.get('/api/risk_groups', params),
      show: id => api.get(`/api/risk_groups/${id}`),
    },
    nihApplicabilities: {
      index: params => api.get('/api/nih_applicabilities', params),
      show: id => api.get(`/api/nih_applicabilities/${id}`),
    },
    trainingFormats: {
      index: params => api.get('/api/training_formats', params),
      show: id => api.get(`/api/training_formats/${id}`),
    },
    trainingTypes: {
      index: params => api.get('/api/training_types', params),
      show: id => api.get(`/api/training_types/${id}`),
    },
    equipmentTypes: {
      index: params => api.get('/api/equipment_types', params),
      show: id => api.get(`/api/equipment_types/${id}`),
    },
    ehspoiTypes: {
      index: params => api.get('/api/ehspoi_types', params),
      show: id => api.get(`/api/ehspoi_types/${id}`),
    },
    inspectionFindingDefinitions: {
      index: params => api.get('/api/inspection_finding_definitions', params),
      show: id => api.get(`api/inspection_finding_definitions/${id}?verbose_select_label=true`),
    },
    inspectionFindingCategories: {
      index: params => api.get('/api/inspection_finding_categories', params),
      show: id => api.get(`/api/inspection_finding_categories/${id}`),
    },
    inspectionFindingDefinitionsTypes: {
      index: params => api.get('/api/inspection_finding_definitions_types', params),
      show: id => api.get(`/api/inspection_finding_definitions_types/${id}`),
    },
    vendors: {
      index: params => api.get('/api/vendors', params),
      show: id => api.get(`/api/vendors/${id}`),
    },
    radLicenses: {
      index: params => api.get('/api/rad_licenses', params),
      show: id => api.get(`/api/rad_licenses/${id}`),
    },
    radLicenseItemAssignments: {
      index: params => api.get('/api/rad_license_item_assignments', params),
      show: id => api.get(`/api/rad_license_item_assignments/${id}`),
    },
    radPermits: {
      index: params => api.get('/api/rad_permits', params),
      show: id => api.get(`/api/rad_permits/${id}`),
    },
    radInventories: {
      index: params => api.get('/api/rad_inventories', params),
      show: id => api.get(`/api/rad_inventories/${id}`),
    },
    radInventoryItems: {
      index: params => api.get('/api/rad_inventory_items', params),
      show: id => api.get(`/api/rad_inventory_items/${id}`),
    },
    radIsotopes: {
      index: params => api.get('/api/rad_isotopes', params),
      show: id => api.get(`/api/rad_isotopes/${id}`),
    },
    radIsotopesGroups: {
      index: params => api.get('/api/rad_isotopes_groups', params),
      show: id => api.get(`/api/rad_isotopes_groups/${id}`),
    },
    radCompounds: {
      index: params => api.get('/api/rad_compounds', params),
      show: id => api.get(`/api/rad_compounds/${id}`),
    },
    radItemTypes: {
      index: params => api.get('/api/rad_item_types', params),
      show: id => api.get(`/api/rad_item_types/${id}`),
    },
    radInventoryItemStatuses: {
      index: params => api.get('/api/rad_inventory_item_statuses', params),
      show: id => api.get(`/api/rad_inventory_item_statuses/${id}`),
    },
    radForms: {
      index: params => api.get('/api/rad_forms', params),
      show: id => api.get(`/api/rad_forms/${id}`),
    },
    radUserTypes: {
      index: params => api.get('/api/rad_user_types', params),
      show: id => api.get(`/api/rad_user_types/${id}`),
    },
    radLicenseItems: {
      index: params => api.get('/api/rad_license_items', params),
      show: id => api.get(`/api/rad_license_items/${id}`),
    },
    radUsers: {
      index: params => api.get('/api/rad_users', params),
      show: id => api.get(`/api/rad_users/${id}`),
    },
    radPurchaseStatuses: {
      index: params => api.get('/api/rad_purchase_statuses', params),
      show: id => api.get(`/api/rad_purchase_statuses/${id}`),
    },
    radPermitTypes: {
      index: params => api.get('/api/rad_permit_types', params),
      show: id => api.get(`/api/rad_permit_types/${id}`),
    },
    radPermitStatuses: {
      index: params => api.get('/api/rad_permit_statuses', params),
      show: id => api.get(`/api/rad_permit_statuses/${id}`),
    },
    impactTypes: {
      index: params => api.get('/api/impact_types', params),
      show: id => api.get(`/api/impact_types/${id}`),
    },
    incidentStatuses: {
      index: params => api.get('/api/incident_statuses', params),
      show: id => api.get(`/api/incident_statuses/${id}`),
    },
    incidentRiskRatings: {
      index: params => api.get('/api/incident_risk_ratings', params),
      show: id => api.get(`/api/incident_risk_ratings/${id}`),
    },
    chemicalStorageGroups: {
      index: params => api.get('/api/chemical_storage_groups', params),
      show: id => api.get(`/api/chemical_storage_groups/${id}`),
    },
    hhopTypes: {
      index: params => api.get('/api/hhop_types', params),
      show: id => api.get(`/api/hhop_types/${id}`),
    },
    chemicalManufacturers: {
      index: params => api.get('/api/chemical_manufacturers', params),
      show: id => api.get(`/api/chemical_manufacturers/${id}`),
      create: params => api.post('/api/chemical_manufacturers', params),
    },
    ghsCodes: {
      index: params => api.get('/api/ghs_codes', params),
      show: id => api.get(`/api/ghs_codes/${id}`),
    },
    pchlProducts: {
      index: params => api.get('/api/pchl_products', params),
      show: (id, params) => api.get(`/api/pchl_products/${id}`, params),
    },
    addableChemicalCompounds: {
      index: params => api.get('/api/addable_chemical_compounds', params),
      show: (cid, params) => api.get(`/api/addable_chemical_compounds/${cid}`, params),
    },
    chemicalInventories: {
      index: params => api.get('/api/chemical_inventories', params),
      show: id => api.get(`/api/chemical_inventories/${id}`),
    },
    chemicalDefinitions: {
      index: params => api.get('/api/chemical_definitions', params),
      show: id => api.get(`/api/chemical_definitions/${id}`),
    },
    equipmentStatuses: {
      index: params => api.get('/api/equipment_statuses', params),
      show: id => api.get(`/api/equipment_statuses/${id}`),
    },
    namedFilters: {
      index: params => api.get('/api/named_filters', params),
      show: id => api.get(`/api/named_filters/${id}`),
    },
    accidentSettings: {
      index: params => api.get('/api/accident_settings', params),
      show: id => api.get(`/api/accident_settings/${id}`),
    },
    accidentOshaRiskFactors: {
      index: params => api.get('/api/accident_osha_risk_factors', params),
      show: id => api.get(`/api/accident_osha_risk_factors/${id}`),
    },
    accidentInjuryTypes: {
      index: params => api.get('/api/accident_injury_types', params),
      show: id => api.get(`/api/accident_injury_types/${id}`),
    },
    accidentClasses: {
      index: params => api.get('/api/accident_classes', params),
      show: id => api.get(`/api/accident_classes/${id}`),
    },
    accidentBodyParts: {
      index: params => api.get('/api/accident_body_parts', params),
      show: id => api.get(`/api/accident_body_parts/${id}`),
    },
    accidentUnsafeActs: {
      index: params => api.get('/api/accident_unsafe_acts', params),
      show: id => api.get(`/api/accident_unsafe_acts/${id}`),
    },
    accidentUnsafePersonalFactors: {
      index: params => api.get('/api/accident_unsafe_personal_factors', params),
      show: id => api.get(`/api/accident_unsafe_personal_factors/${id}`),
    },
    accidentUnsafeConditions: {
      index: params => api.get('/api/accident_unsafe_conditions', params),
      show: id => api.get(`/api/accident_unsafe_conditions/${id}`),
    },
  },
  cfgIndexPages: {
    show: model => api.get(`/salute_portal/api/cfg_index_pages/${model}`),
    update: (model, params) => api.patch(`/salute_portal/api/cfg_index_pages/${model}`, params),
  },
  occurrences: {
    incidents: {
      new: () => api.get('/salute_portal/api/occurrences/incidents/incidents/new'),
      create: params => api.post('/salute_portal/api/occurrences/incidents/incidents', params),
      show: id => api.get(`/salute_portal/api/occurrences/incidents/incidents/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/occurrences/incidents/incidents/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/occurrences/incidents/incidents/${id}`),
      incidentRequests: incidentId => ({
        index: params =>
          api.get(
            `/salute_portal/api/occurrences/incidents/incidents/${incidentId}/incident_requests`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/occurrences/incidents/incidents/${incidentId}/incident_requests`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/occurrences/incidents/incidents/${incidentId}/incident_requests/${id}`,
          ),
      }),
    },
    accidents: {
      create: params => api.post('/salute_portal/api/occurrences/accidents/accidents', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/occurrences/accidents/accidents/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/occurrences/accidents/accidents/${id}`),
      resolve: (id, params) =>
        api.patch(`/salute_portal/api/occurrences/accidents/accidents/${id}/resolve`, params),
      reopen: (id, params) =>
        api.patch(`/salute_portal/api/occurrences/accidents/accidents/${id}/reopen`, params),
      link_incident: (id, params) =>
        api.patch(`/salute_portal/api/occurrences/accidents/accidents/${id}/link_incident`, params),
      unlink_incident: (id, params) =>
        api.patch(
          `/salute_portal/api/occurrences/accidents/accidents/${id}/unlink_incident`,
          params,
        ),
      new: () => api.get('/salute_portal/api/occurrences/accidents/accidents/new'),
      show: id => api.get(`/salute_portal/api/occurrences/accidents/accidents/${id}`),
      incidentRequests: accidentId => ({
        index: params =>
          api.get(
            `/salute_portal/api/occurrences/accidents/accidents/${accidentId}/incident_requests`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/occurrences/accidents/accidents/${accidentId}/incident_requests`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/occurrences/accidents/accidents/${accidentId}/incident_requests/${id}`,
          ),
      }),
    },
  },
  requests: {
    generalRequests: {
      create: params =>
        api.post('/salute_portal/api/requests/general_requests/general_requests', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/requests/general_requests/general_requests/${id}`, params),
      destroy: id =>
        api.delete(`/salute_portal/api/requests/general_requests/general_requests/${id}`),
      resolve: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/general_requests/general_requests/${id}/resolve`,
          params,
        ),
      reopen: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/general_requests/general_requests/${id}/reopen`,
          params,
        ),
      new: () => api.get('/salute_portal/api/requests/general_requests/general_requests/new'),
      show: id => api.get(`/salute_portal/api/requests/general_requests/general_requests/${id}`),
      comments: (id, commentableRole) =>
        initCommentsAPI(`/requests/general_requests/${id}/comments`, commentableRole),
    },
    generalRequestTypes: id => ({
      fieldDefinitions: () => ({
        index: () =>
          api.get(
            `/salute_portal/api/requests/general_requests/general_request_types/${id}/field_definitions`,
          ),
      }),
    }),
    incidentRequests: {
      create: params =>
        api.post('/salute_portal/api/requests/incident_requests/incident_requests', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/requests/incident_requests/incident_requests/${id}`, params),
      destroy: id =>
        api.delete(`/salute_portal/api/requests/incident_requests/incident_requests/${id}`),
      reopen: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/reopen`,
          params,
        ),
      resolve: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/resolve`,
          params,
        ),
      resolveAndCreateIncident: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/resolve_and_create_incident`,
          params,
        ),
      resolveAndAssignIncident: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/resolve_and_assign_incident`,
          params,
        ),
      resolveAndCreateAccident: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/resolve_and_create_accident`,
          params,
        ),
      resolveAndAssignAccident: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/incident_requests/incident_requests/${id}/resolve_and_assign_accident`,
          params,
        ),
      new: () => api.get('/salute_portal/api/requests/incident_requests/incident_requests/new'),
      show: id => api.get(`/salute_portal/api/requests/incident_requests/incident_requests/${id}`),
      comments: (id, commentableRole) =>
        initCommentsAPI(`/requests/incident_requests/${id}/comments`, commentableRole),
      incidents: incidentRequestId => ({
        index: params =>
          api.get(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/incidents`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/incidents`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/incidents/${id}`,
          ),
      }),
      accidents: incidentRequestId => ({
        index: params =>
          api.get(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/accidents`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/accidents`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/incident_requests/incident_requests/${incidentRequestId}/accidents/${id}`,
          ),
      }),
    },
    eventTypes: id => ({
      fieldDefinitions: () => ({
        index: () =>
          api.get(
            `/salute_portal/api/requests/incident_requests/event_types/${id}/field_definitions`,
          ),
      }),
    }),
    safeWorkAuthorizations: {
      create: params =>
        api.post(
          '/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations',
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${id}`,
          params,
        ),
      reopen: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${id}/reopen`,
          params,
        ),
      resolve: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${id}/resolve`,
          params,
        ),
      new: () =>
        api.get(
          '/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/new',
        ),
      show: id =>
        api.get(
          `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${id}`,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${id}`,
        ),
      comments: (swaId, commentableRole) =>
        initCommentsAPI(`/requests/safe_work_authorizations/${swaId}/comments`, commentableRole),
      locations: swaId => ({
        index: params =>
          api.get(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_locations`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_locations`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_locations/${id}`,
          ),
      }),
      measures: swaId => ({
        index: () =>
          api.get(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures`,
          ),
        new: () =>
          api.get(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/new`,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${id}`,
          ),
        equipment: swaMeasureId => ({
          index: () =>
            api.get(
              `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${swaMeasureId}/safe_work_authorization_measure_equipments`,
            ),
          create: params =>
            api.post(
              `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${swaMeasureId}/safe_work_authorization_measure_equipments`,
              params,
            ),
          destroy: id =>
            api.delete(
              `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${swaMeasureId}/safe_work_authorization_measure_equipments/${id}`,
            ),
        }),
      }),
      construction_projects: swaId => ({
        index: params =>
          api.get(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_construction_projects`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_construction_projects`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${swaId}/safe_work_authorization_construction_projects/${id}`,
          ),
      }),
    },
    wasteRequests: {
      new: () => api.get('/salute_portal/api/requests/waste_requests/waste_requests/new'),
      show: id => api.get(`/salute_portal/api/requests/waste_requests/waste_requests/${id}`),
      create: params =>
        api.post('/salute_portal/api/requests/waste_requests/waste_requests', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/requests/waste_requests/waste_requests/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/requests/waste_requests/waste_requests/${id}`),
      resolve: (id, params) =>
        api.patch(
          `/salute_portal/api/requests/waste_requests/waste_requests/${id}/resolve`,
          params,
        ),
      reopen: (id, params) =>
        api.patch(`/salute_portal/api/requests/waste_requests/waste_requests/${id}/reopen`, params),
      comments: (id, commentableRole) =>
        initCommentsAPI(`/requests/waste_requests/${id}/comments`, commentableRole),
      wasteRequestContainers: wasteRequestId => ({
        index: () =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers`,
          ),
        new: () =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers/new`,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_request_containers/${id}`,
          ),
      }),
      wasteCollectionCandidates: wasteRequestId => ({
        index: params =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates`,
            params,
          ),
        new: () =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates/new`,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/requests/waste_requests/waste_requests/${wasteRequestId}/waste_collection_candidates/${id}`,
          ),
      }),
      wasteContainers: id => `/requests/waste_requests/${id}/requested_containers.xlsx`,
      wasteCollections: id => `/requests/waste_requests/${id}/requested_collections.xlsx`,
    },
    wasteCollectionTypes: id => ({
      fieldDefinitions: () => ({
        index: () =>
          api.get(
            `/salute_portal/api/requests/waste_requests/waste_collection_types/${id}/field_definitions`,
          ),
      }),
    }),
  },
  locations: {
    campuses: {
      new: () => api.get('/salute_portal/api/campuses/new'),
      create: params => api.post('/salute_portal/api/campuses', params),
      show: id => api.get(`/salute_portal/api/campuses/${id}`),
      update: (id, params) => api.patch(`/salute_portal/api/campuses/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/campuses/${id}`),
    },
    buildings: {
      index: params => api.get('salute_portal/api/buildings', params),
    },
    floors: {
      index: params => api.get('salute_portal/api/buildings_floors', params),
    },
    spaces: {
      index: params => api.get('salute_portal/api/labs', params),
    },
  },
  investigables: {
    relatedItems: (id, type) =>
      api.get('investigables/related_items', {
        investigable_type: type,
        investigable_id: id,
      }),
  },
  settings: {
    index: params => api.get('salute_portal/api/settings', params),
    update: params => api.patch('salute_portal/api/settings', params),
  },
  namedFilters: {
    index: params => api.get('salute_portal/api/named_filters', params),
    create: params => api.post('/salute_portal/api/named_filters', params),
    destroy: id => api.delete(`/salute_portal/api/named_filters/${id}`),
  },
  constructionProjects: {
    new: () => api.get('/salute_portal/api/construction_projects/construction_projects/new'),
    create: params =>
      api.post('/salute_portal/api/construction_projects/construction_projects', params),
    show: id => api.get(`/salute_portal/api/construction_projects/construction_projects/${id}`),
    update: (id, params) =>
      api.patch(`/salute_portal/api/construction_projects/construction_projects/${id}`, params),
    destroy: id =>
      api.delete(`/salute_portal/api/construction_projects/construction_projects/${id}`),
    locations: constructionProjectId => ({
      index: params =>
        api.get(
          `/salute_portal/api/construction_projects/construction_projects/${constructionProjectId}/construction_project_locations`,
          params,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/construction_projects/construction_projects/${constructionProjectId}/construction_project_locations`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/construction_projects/construction_projects/${constructionProjectId}/construction_project_locations/${id}`,
        ),
    }),
  },
  permits: {
    new: () => api.get('/salute_portal/api/permits/permits/new'),
    create: params => api.post('/salute_portal/api/permits/permits', params),
    show: id => api.get(`/salute_portal/api/permits/permits/${id}`),
    update: (id, params) => api.patch(`/salute_portal/api/permits/permits/${id}`, params),
    destroy: id => api.delete(`/salute_portal/api/permits/permits/${id}`),
    locations: permitId => ({
      index: params =>
        api.get(`/salute_portal/api/permits/permits/${permitId}/permit_locations`, params),
      create: params =>
        api.post(`/salute_portal/api/permits/permits/${permitId}/permit_locations`, params),
      destroy: id =>
        api.delete(`/salute_portal/api/permits/permits/${permitId}/permit_locations/${id}`),
    }),
  },
  permitTypes: id => ({
    fieldDefinitions: () => ({
      index: () => api.get(`/salute_portal/api/permits/permit_types/${id}/field_definitions`),
    }),
  }),
  radiationSafety: {
    radPermits: {
      index: params => api.get('/salute_portal/api/radiation_safety/rad_permits', params),
      new: params => api.get('/salute_portal/api/radiation_safety/rad_permits/new', params),
      create: params => api.post('/salute_portal/api/radiation_safety/rad_permits', params),
      show: id => api.get(`/salute_portal/api/radiation_safety/rad_permits/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/radiation_safety/rad_permits/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/radiation_safety/rad_permits/${id}`),
      equipment: radPermitId => ({
        index: () =>
          api.get(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_equipments`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_equipments`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_equipments/${id}`,
          ),
      }),
      locations: radPermitId => ({
        index: params =>
          api.get(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_locations`,
            params,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_locations`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_permit_locations/${id}`,
          ),
      }),
    },
    radLicenses: {
      new: () => api.get('/salute_portal/api/radiation_safety/rad_licenses/new'),
      create: params => api.post('/salute_portal/api/radiation_safety/rad_licenses', params),
      show: id => api.get(`/salute_portal/api/radiation_safety/rad_licenses/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/radiation_safety/rad_licenses/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/radiation_safety/rad_licenses/${id}`),
    },
    radLicenseItems: radLicenseId => ({
      index: params =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items`,
          params,
        ),
      new: () =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items/new`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items`,
          params,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items/${id}`,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/radiation_safety/rad_licenses/${radLicenseId}/rad_license_items/${id}`,
        ),
    }),
    radLicenseItemAssignments: radPermitId => ({
      index: params =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments`,
          params,
        ),
      new: () =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments/new`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments`,
          params,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments/${id}`,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments/${id}`,
        ),
      limits: (id, params) =>
        api.patch(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_license_item_assignments/${id}/limits`,
          params,
        ),
    }),
    radInventories: {
      new: () => api.get('/salute_portal/api/radiation_safety/rad_inventories/new'),
      create: params => api.post('/salute_portal/api/radiation_safety/rad_inventories', params),
      show: id => api.get(`/salute_portal/api/radiation_safety/rad_inventories/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/radiation_safety/rad_inventories/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/radiation_safety/rad_inventories/${id}`),
    },
    radInventoryItems: inventoryId => ({
      index: params => api.get('salute_portal/api/radiation_safety/rad_inventory_items', params),
      new: params =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/new`,
          params,
        ),
      create: params =>
        api.post(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items`,
          params,
        ),
      show: id =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}`,
        ),
      update: (id, params) =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}`,
          params,
        ),
      dispose: (id, params) =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}/dispose`,
          params,
        ),
      reopen: (id, params) =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}/reopen`,
          params,
        ),
      destroy: id =>
        api.delete(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}`,
        ),
      moveToAnotherInventory: (id, params) =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${id}/move_to_another_inventory`,
          params,
        ),
      moveMultipleToAnotherInventory: params =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/multiple_move_to_another_inventory`,
          params,
        ),
      wasteCollections: radInventoryItemId => ({
        index: params =>
          api.get(
            `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${radInventoryItemId}/waste_chemical_items`,
            params,
          ),
        create: params =>
          api.post(
            `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${radInventoryItemId}/waste_chemical_items`,
            params,
          ),
        destroy: id =>
          api.delete(
            `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${radInventoryItemId}/waste_chemical_items/${id}`,
          ),
      }),
    }),
    radWithdrawals: (inventoryId, inventoryItemId) => ({
      index: params =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals`,
          params,
        ),
      new: () =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals/new`,
        ),
      create: params =>
        api.post(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals`,
          params,
        ),
      show: id =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals/${id}`,
        ),
      update: (id, params) =>
        api.patch(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_withdrawals/${id}`,
        ),
    }),
    radInventoryItemSplits: (inventoryId, inventoryItemId) => ({
      index: params =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_inventory_item_splits`,
          params,
        ),
      new: () =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_inventory_item_splits/new`,
        ),
      create: params =>
        api.post(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_inventory_item_splits`,
          params,
        ),
      show: id =>
        api.get(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_inventory_item_splits/${id}`,
        ),
      destroy: id =>
        api.delete(
          `salute_portal/api/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${inventoryItemId}/rad_inventory_item_splits/${id}`,
        ),
    }),
    radPurchases: {
      index: params => api.get('/salute_portal/api/radiation_safety/rad_purchases', params),
      new: params => api.get('/salute_portal/api/radiation_safety/rad_purchases/new', params),
      create: params => api.post('/salute_portal/api/radiation_safety/rad_purchases', params),
      show: id => api.get(`/salute_portal/api/radiation_safety/rad_purchases/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/radiation_safety/rad_purchases/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/radiation_safety/rad_purchases/${id}`),
    },
    radUsers: radPermitId => ({
      index: params =>
        api.get(`/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users`, params),
      new: () =>
        api.get(`/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users/new`),
      create: params =>
        api.post(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users`,
          params,
        ),
      show: id =>
        api.get(`/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users/${id}`),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/radiation_safety/rad_permits/${radPermitId}/rad_users/${id}`,
        ),
    }),
  },
  spaceRiskAssessments: {
    new: () => api.get('/salute_portal/api/space_risk_assessments/space_risk_assessments/new'),
    create: params =>
      api.post('/salute_portal/api/space_risk_assessments/space_risk_assessments', params),
    show: id => api.get(`/salute_portal/api/space_risk_assessments/space_risk_assessments/${id}`),
    generateDoorSign: id => api.get(`/hsds/hazard_assessments/${id}`),
    update: (id, params) =>
      api.patch(`/salute_portal/api/space_risk_assessments/space_risk_assessments/${id}`, params),
    destroy: id =>
      api.delete(`/salute_portal/api/space_risk_assessments/space_risk_assessments/${id}`),
    emergencyContactProfiles: {
      new: () => api.get('salute_portal/api/space_risk_assessments/emergency_contact_profiles/new'),
      create: params =>
        api.post('/salute_portal/api/space_risk_assessments/emergency_contact_profiles', params),
      show: id =>
        api.get(`/salute_portal/api/space_risk_assessments/emergency_contact_profiles/${id}`),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/space_risk_assessments/emergency_contact_profiles/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(`/salute_portal/api/space_risk_assessments/emergency_contact_profiles/${id}`),
    },
    logoProfiles: {
      new: () => api.get('salute_portal/api/space_risk_assessments/logo_profiles/new'),
      create: params => api.post('/salute_portal/api/space_risk_assessments/logo_profiles', params),
      show: id => api.get(`/salute_portal/api/space_risk_assessments/logo_profiles/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/space_risk_assessments/logo_profiles/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/space_risk_assessments/logo_profiles/${id}`),
    },
    hsdsEmergencyContacts: spaceRiskAssessmentId => ({
      index: params =>
        api.get(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts`,
          params,
        ),
      new: () =>
        api.get(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts/new`,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts/${id}`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts`,
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/hsds_emergency_contacts/${id}`,
        ),
    }),
    locations: spaceRiskAssessmentId => ({
      index: params =>
        api.get(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/space_risk_assessment_locations`,
          params,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/space_risk_assessment_locations`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/space_risk_assessments/space_risk_assessments/${spaceRiskAssessmentId}/space_risk_assessment_locations/${id}`,
        ),
    }),
  },
  documents: {
    new: () => api.get('/salute_portal/api/documents/documents/new'),
    create: params => api.post('/salute_portal/api/documents/documents', params),
    show: id => api.get(`/salute_portal/api/documents/documents/${id}`),
    update: (id, params) => api.patch(`/salute_portal/api/documents/documents/${id}`, params),
    destroy: id => api.delete(`/salute_portal/api/documents/documents/${id}`),
    locations: documentId => ({
      index: params =>
        api.get(`/salute_portal/api/documents/documents/${documentId}/document_locations`, params),
      create: params =>
        api.post(`/salute_portal/api/documents/documents/${documentId}/document_locations`, params),
      destroy: id =>
        api.delete(`/salute_portal/api/documents/documents/${documentId}/document_locations/${id}`),
    }),
  },
  administration: {
    labels: {
      new: () => api.get('/salute_portal/api/administration/labels/new'),
      show: id => api.get(`/salute_portal/api/administration/labels/${id}`),
      create: params => api.post('/salute_portal/api/administration/labels', params),
      update: (id, params) => api.patch(`/salute_portal/api/administration/labels/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/labels/${id}`),
    },
    biologicalAgentTypes: {
      new: () => api.get('/salute_portal/api/administration/biological_agent_types/new'),
      show: id => api.get(`/salute_portal/api/administration/biological_agent_types/${id}`),
      create: params =>
        api.post('/salute_portal/api/administration/biological_agent_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/biological_agent_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/biological_agent_types/${id}`),
    },
    species: {
      index: params => api.get(`/salute_portal/api/administration/biological_bug_species`, params),
      new: params =>
        api.get('/salute_portal/api/administration/biological_bug_species/new', params),
      show: id => api.get(`/salute_portal/api/administration/biological_bug_species/${id}`),
      create: params =>
        api.post('/salute_portal/api/administration/biological_bug_species', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/biological_bug_species/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/biological_bug_species/${id}`),
    },
    strains: {
      index: params => api.get(`/salute_portal/api/administration/biological_strains`, params),
      new: params => api.get('/salute_portal/api/administration/biological_strains/new', params),
      show: id => api.get(`/salute_portal/api/administration/biological_strains/${id}`),
      create: params => api.post('/salute_portal/api/administration/biological_strains', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/biological_strains/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/biological_strains/${id}`),
    },
    equipmentTypes: {
      new: params => api.get('/salute_portal/api/administration/equipment_types/new', params),
      show: id => api.get(`/salute_portal/api/administration/equipment_types/${id}`),
      create: params => api.post('/salute_portal/api/administration/equipment_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/equipment_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/equipment_types/${id}`),
    },
    equipmentFieldDefinitions: equipmentTypeId => ({
      index: params =>
        api.get(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions`,
          params,
        ),
      new: params =>
        api.get(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions/new`,
          params,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions/${id}`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions`,
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions/${id}`,
        ),
      reorder: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/equipment_types/${equipmentTypeId}/equipment_field_definitions/${id}/reorder`,
          params,
        ),
    }),
    wasteCollectionTypes: {
      new: params =>
        api.get('/salute_portal/api/administration/waste_collection_types/new', params),
      show: id => api.get(`/salute_portal/api/administration/waste_collection_types/${id}`),
      create: params =>
        api.post('/salute_portal/api/administration/waste_collection_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/waste_collection_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/waste_collection_types/${id}`),
    },
    wasteChemicalItemCandidateFieldDefinitions: wasteCollectionTypeId => ({
      index: params =>
        api.get(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions`,
          params,
        ),
      new: params =>
        api.get(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions/new`,
          params,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions/${id}`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions`,
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions/${id}`,
        ),
      reorder: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/waste_collection_types/${wasteCollectionTypeId}/waste_chemical_item_candidate_field_definitions/${id}/reorder`,
          params,
        ),
    }),
    assessmentTypes: {
      index: params => api.get('/salute_portal/api/administration/assessment_types', params),
      new: params => api.get('/salute_portal/api/administration/assessment_types/new', params),
      show: id => api.get(`/salute_portal/api/administration/assessment_types/${id}`),
      create: params => api.post('/salute_portal/api/administration/assessment_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/assessment_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/assessment_types/${id}`),
    },
    externalInspectionTypes: {
      new: params =>
        api.get('/salute_portal/api/administration/external_inspection_types/new', params),
      show: id => api.get(`/salute_portal/api/administration/external_inspection_types/${id}`),
      create: params =>
        api.post('/salute_portal/api/administration/external_inspection_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/external_inspection_types/${id}`, params),
      destroy: id =>
        api.delete(`/salute_portal/api/administration/external_inspection_types/${id}`),
    },
    inspectionFindingDefinitionsTypes: {
      show: id =>
        api.get(`/salute_portal/api/administration/inspection_finding_definitions_types/${id}`),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/inspection_finding_definitions_types/${id}`,
          params,
        ),
    },
    simpleDictionaryOptions: {
      index: params =>
        api.get('/salute_portal/api/administration/simple_dictionary_options', params),
      new: params =>
        api.get('/salute_portal/api/administration/simple_dictionary_options/new', params),
      show: (id, params) =>
        api.get(`/salute_portal/api/administration/simple_dictionary_options/${id}`, params),
      create: params =>
        api.post('/salute_portal/api/administration/simple_dictionary_options', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/simple_dictionary_options/${id}`, params),
      destroy: (id, params) =>
        api.delete(`/salute_portal/api/administration/simple_dictionary_options/${id}`, params),
      reorder: (id, params) =>
        api.patch(
          `/salute_portal/api/administration/simple_dictionary_options/${id}/reorder`,
          params,
        ),
    },
    wasteGenerators: {
      new: () => api.get('/salute_portal/api/administration/waste_generators/new'),
      create: params => api.post('/salute_portal/api/administration/waste_generators', params),
      show: id => api.get(`/salute_portal/api/administration/waste_generators/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/waste_generators/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/waste_generators/${id}`),
    },
    wasteCafs: {
      new: params => api.get('/salute_portal/api/administration/waste_cafs/new', params),
      create: params => api.post('/salute_portal/api/administration/waste_cafs', params),
      show: id => api.get(`/salute_portal/api/administration/waste_cafs/${id}`),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/waste_cafs/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/waste_cafs/${id}`),
    },
    departments: {
      new: () => api.get('/salute_portal/api/administration/departments/new'),
      show: id => api.get(`/salute_portal/api/administration/departments/${id}`),
      create: params => api.post('/salute_portal/api/administration/departments', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/departments/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/departments/${id}`),
    },
    ums: {
      index: params => api.get('/salute_portal/api/administration/ums', params),
      new: () => api.get('/salute_portal/api/administration/ums/new'),
      show: id => api.get(`/salute_portal/api/administration/ums/${id}`),
      create: params => api.post('/salute_portal/api/administration/ums', params),
      update: (id, params) => api.patch(`/salute_portal/api/administration/ums/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/ums/${id}`),
      reorder: (id, params) =>
        api.patch(`/salute_portal/api/administration/ums/${id}/reorder`, params),
    },
    divisions: {
      new: () => api.get('/salute_portal/api/administration/divisions/new'),
      show: id => api.get(`/salute_portal/api/administration/divisions/${id}`),
      create: params => api.post('/salute_portal/api/administration/divisions', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/divisions/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/divisions/${id}`),
    },
    generalRequestTypes: {
      new: () => api.get('/salute_portal/api/administration/general_request_types/new'),
      show: id => api.get(`/salute_portal/api/administration/general_request_types/${id}`),
      create: params => api.post('/salute_portal/api/administration/general_request_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/general_request_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/general_request_types/${id}`),
      fieldDefinitions: generalRequestTypeId => ({
        index: params =>
          api.get(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions`,
            params,
          ),
        new: params =>
          api.get(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions/new`,
            params,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions/${id}`,
          ),
        reorder: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/general_request_types/${generalRequestTypeId}/general_request_field_definitions/${id}/reorder`,
            params,
          ),
      }),
    },
    eventTypes: {
      new: () => api.get('/salute_portal/api/administration/event_types/new'),
      show: id => api.get(`/salute_portal/api/administration/event_types/${id}`),
      create: params => api.post('/salute_portal/api/administration/event_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/event_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/event_types/${id}`),
      fieldDefinitions: eventTypeId => ({
        index: params =>
          api.get(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions`,
            params,
          ),
        new: params =>
          api.get(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions/new`,
            params,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions/${id}`,
          ),
        reorder: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/event_types/${eventTypeId}/incident_request_field_definitions/${id}/reorder`,
            params,
          ),
      }),
    },
    customEmailTemplates: {
      new: () => api.get('/salute_portal/api/administration/custom_email_templates/new'),
      show: id => api.get(`/salute_portal/api/administration/custom_email_templates/${id}`),
      create: params =>
        api.post('/salute_portal/api/administration/custom_email_templates', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/custom_email_templates/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/custom_email_templates/${id}`),
    },
    permitTypes: {
      new: () => api.get('/salute_portal/api/administration/permit_types/new'),
      show: id => api.get(`/salute_portal/api/administration/permit_types/${id}`),
      create: params => api.post('/salute_portal/api/administration/permit_types', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/permit_types/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/permit_types/${id}`),
      fieldDefinitions: permitTypeId => ({
        index: params =>
          api.get(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions`,
            params,
          ),
        new: params =>
          api.get(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions/new`,
            params,
          ),
        show: id =>
          api.get(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions/${id}`,
          ),
        create: params =>
          api.post(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions`,
            params,
          ),
        update: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions/${id}`,
            params,
          ),
        destroy: id =>
          api.delete(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions/${id}`,
          ),
        reorder: (id, params) =>
          api.patch(
            `/salute_portal/api/administration/permit_types/${permitTypeId}/permits_fdny_field_definitions/${id}/reorder`,
            params,
          ),
      }),
    },
    radIsotopesGroups: {
      new: () => api.get('/salute_portal/api/administration/rad_isotopes_groups/new'),
      show: id => api.get(`/salute_portal/api/administration/rad_isotopes_groups/${id}`),
      create: params => api.post('/salute_portal/api/administration/rad_isotopes_groups', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/administration/rad_isotopes_groups/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/administration/rad_isotopes_groups/${id}`),
    },
    radIsotopes: groupId => ({
      index: () =>
        api.get(`/salute_portal/api/administration/rad_isotopes_groups/${groupId}/rad_isotopes`),
      addToGroup: radIsotopeId =>
        api.patch(
          `/salute_portal/api/administration/rad_isotopes_groups/${groupId}/rad_isotopes/${radIsotopeId}/add_to_group`,
        ),
      removeFromGroup: radIsotopeId =>
        api.patch(
          `/salute_portal/api/administration/rad_isotopes_groups/${groupId}/rad_isotopes/${radIsotopeId}/remove_from_group`,
        ),
    }),
  },
  biologicalSafety: {
    ibcRegistrations: {
      index: params => api.get('/salute_portal/api/biological_safety/ibc_registrations', params),
      new: () => api.get('/salute_portal/api/biological_safety/ibc_registrations/new'),
      show: id => api.get(`/salute_portal/api/biological_safety/ibc_registrations/${id}`),
      create: params => api.post('/salute_portal/api/biological_safety/ibc_registrations', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/biological_safety/ibc_registrations/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/biological_safety/ibc_registrations/${id}`),
    },
    biologicalAgents: ibcRegistrationId => ({
      index: params =>
        api.get(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents`,
          params,
        ),
      new: () =>
        api.get(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents/new`,
        ),
      show: id =>
        api.get(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents/${id}`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents`,
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/biological_safety/ibc_registrations/${ibcRegistrationId}/biological_agents/${id}`,
        ),
    }),
  },
  employeeTrainings: {
    employeeTrainings: {
      new: () => api.get('/salute_portal/api/employee_trainings/employee_trainings/new'),
      show: id => api.get(`/salute_portal/api/employee_trainings/employee_trainings/${id}`),
      create: params =>
        api.post('/salute_portal/api/employee_trainings/employee_trainings/', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/employee_trainings/employee_trainings/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/employee_trainings/employee_trainings/${id}`),
      attendees: id => `/training/employee_trainings/${id}/attendees.xlsx`,
    },
    employeeTrainingPeople: employeeTrainingId => ({
      index: () =>
        api.get(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_people`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_people`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_people/${id}`,
        ),
    }),
    employeeTrainingLocations: employeeTrainingId => ({
      index: () =>
        api.get(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_locations`,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_locations`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/employee_trainings/employee_trainings/${employeeTrainingId}/employee_training_locations/${id}`,
        ),
    }),
  },
  equipment: {
    equipment: {
      new: () => api.get('/salute_portal/api/equipment/equipment/new'),
      show: id => api.get(`/salute_portal/api/equipment/equipment/${id}`),
      create: params => api.post('/salute_portal/api/equipment/equipment', params),
      update: (id, params) => api.patch(`/salute_portal/api/equipment/equipment/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/equipment/equipment/${id}`),
      printLabel: params => api.get('/inventory/equipment/equipment/print_labels_pdf', params),
    },
    equipmentFieldDefinitions: {
      index: params => api.get('/salute_portal/api/equipment/equipment_field_definitions', params),
    },
  },
  people: {
    people: {
      new: () => api.get('/salute_portal/api/people/people/new'),
      show: id => api.get(`/salute_portal/api/people/people/${id}`),
      create: params => api.post('/salute_portal/api/people/people', params),
      update: (id, params) => api.patch(`/salute_portal/api/people/people/${id}`, params),
      update_ehs_info: (id, params) =>
        api.patch(`/salute_portal/api/people/people/${id}/update_ehs_info`, params),
      destroy: id => api.delete(`/salute_portal/api/people/people/${id}`),
      create_user: (id, params) =>
        api.post(`/salute_portal/api/people/people/${id}/create_user`, params),
      update_password: id => api.post(`/salute_portal/api/people/people/${id}/reset_user_password`),
      group_memberships: personId => ({
        index: params =>
          api.get(`/salute_portal/api/people/people/${personId}/group_memberships`, params),
        show: id => api.get(`/salute_portal/api/people/people/${personId}/group_memberships/${id}`),
        new: () => api.get(`/salute_portal/api/people/people/${personId}/group_memberships`),
        create: params =>
          api.post(`/salute_portal/api/people/people/${personId}/group_memberships`, params),
        update: (params, id) =>
          api.patch(`/salute_portal/api/people/people/${personId}/group_memberships/${id}`, params),
        destroy: id =>
          api.delete(`/salute_portal/api/people/people/${personId}/group_memberships/${id}`),
      }),
    },
    employeeTrainingNeeds: personId => ({
      show: () => api.get(`/salute_portal/api/people/people/${personId}/employee_training_needs`),
      update: params =>
        api.put(`/salute_portal/api/people/people/${personId}/employee_training_needs`, {
          training_types_ids: params,
        }),
    }),
  },
  lmsApiToken: {
    show: () => api.get('/lms_api_token'),
  },
  chemicalSafety: {
    chemicalDefinitions: {
      new: () => api.get('/salute_portal/api/chemical_safety/chemical_definitions/new'),
      show: id => api.get(`/salute_portal/api/chemical_safety/chemical_definitions/${id}`),
      create: params => api.post('/salute_portal/api/chemical_safety/chemical_definitions', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/chemical_safety/chemical_definitions/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/chemical_safety/chemical_definitions/${id}`),
      generateSds: id =>
        api.get(`/salute_portal/api/chemical_safety/chemical_definitions/${id}/generate_sds`),
    },
    chemicals: chemicalInventoryId => ({
      index: params => api.get('/salute_portal/api/chemical_safety/chemicals', params),
      exportToXlsxReport: params => api.get('chemical_safety/chemicals.xlsx', params),
      new: params =>
        api.get(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/new`,
          params,
        ),
      show: (id, params) =>
        api.get(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/${id}`,
          params,
        ),
      create: params =>
        api.post(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals`,
          params,
        ),
      update: (id, params) =>
        api.patch(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/${id}`,
          params,
        ),
      destroy: id =>
        api.delete(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/${id}`,
        ),
      createSimilar: (id, params) =>
        api.post(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/${id}/create_similar`,
          params,
        ),
      moveToAnotherInventory: (id, params) =>
        api.patch(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/${id}/move_to_another_inventory`,
          params,
        ),
      multipleMoveToAnotherInventory: (_, params) =>
        api.patch(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/multiple_move_to_another_inventory`,
          params,
        ),
      multipleDestroy: params =>
        api.delete(
          `/salute_portal/api/chemical_safety/chemical_inventories/${chemicalInventoryId}/chemicals/multiple_destroy`,
          params,
        ),
      printLabelsPdf: params => api.get('/chemical_safety/chemicals/print_labels_pdf', params),
    }),
    chemicalDefinitionsCompounds: {
      index: params =>
        api.get('/salute_portal/api/chemical_safety/chemical_definitions_compounds', params),
      create: params =>
        api.post('/salute_portal/api/chemical_safety/chemical_definitions_compounds', params),
      show: (id, params) =>
        api.get(`/salute_portal/api/chemical_safety/chemical_definitions_compounds/${id}`, params),
      destroy: (id, params) =>
        api.delete(
          `/salute_portal/api/chemical_safety/chemical_definitions_compounds/${id}`,
          params,
        ),
    },
    chemicalInventories: {
      new: () => api.get('/salute_portal/api/chemical_safety/chemical_inventories/new'),
      show: id => api.get(`/salute_portal/api/chemical_safety/chemical_inventories/${id}`),
      create: params => api.post('/salute_portal/api/chemical_safety/chemical_inventories', params),
      update: (id, params) =>
        api.patch(`/salute_portal/api/chemical_safety/chemical_inventories/${id}`, params),
      destroy: id => api.delete(`/salute_portal/api/chemical_safety/chemical_inventories/${id}`),
    },
  },
  publicChemicalLibrary: {
    selectors: {
      manufacturers: {
        index: params => api.get('/public_chemical_library/selectors/manufacturers', params),
        show: id => api.get(`/public_chemical_library/selectors/manufacturers/${id}`),
        create: params => api.post('/public_chemical_library/selectors/manufacturers', params),
      },
      storageGroups: {
        index: params => api.get('/public_chemical_library/selectors/storage_groups', params),
        show: id => api.get(`/public_chemical_library/selectors/storage_groups/${id}`),
      },
      hhopTypes: {
        index: params => api.get('/public_chemical_library/selectors/hhop_types', params),
        show: id => api.get(`/public_chemical_library/selectors/hhop_types/${id}`),
      },
      products: {
        index: params => api.get('/public_chemical_library/selectors/products', params),
        show: (id, params) => api.get(`/public_chemical_library/selectors/products/${id}`, params),
      },
      addableCompounds: {
        index: params => api.get('/public_chemical_library/selectors/addable_compounds', params),
        show: (cid, params) =>
          api.get(`/public_chemical_library/selectors/addable_compounds/${cid}`, params),
      },
      ghsCodes: {
        index: params => api.get('/public_chemical_library/selectors/ghs_codes', params),
        show: id => api.get(`/public_chemical_library/selectors/ghs_codes/${id}`),
      },
    },
    products: {
      new: () => api.get('/public_chemical_library/api/products/new'),
      show: id => api.get(`/public_chemical_library/api/products/${id}`),
      create: params => api.post('/public_chemical_library/api/products', params),
      update: (id, params) => api.patch(`/public_chemical_library/api/products/${id}`, params),
      merge: (id, params) => api.put(`/public_chemical_library/api/products/${id}/merge`, params),
      destroy: id => api.delete(`/public_chemical_library/api/products/${id}`),
      generateSds: id => api.get(`/public_chemical_library/api/products/${id}/generate_sds`),
    },
    compoundsProducts: {
      create: params => api.post('public_chemical_library/api/compounds_products', params),
      index: params => api.get('public_chemical_library/api/compounds_products', params),
      show: (id, params) =>
        api.get(`/public_chemical_library/api/compounds_products/${id}`, params),
      destroy: (id, params) =>
        api.delete(`/public_chemical_library/api/compounds_products/${id}`, params),
    },
  },
};
